<template>
  <a-modal :title="this.$route.name" :width="900" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" v-bind="formLayout">
			<a-form-item label="隐藏字段" style="display:none;">
			</a-form-item>
      <a-form-item label="文章标题">
        <a-input v-decorator="[ 'title', { rules: [{ required: true, message: '' }] }]" />
      </a-form-item>
			<a-form-item label="文章内容">
			  <editor ref="editor" @update="update"></editor>
				<a-input type="hidden" v-decorator="[ 'info', { rules: [{ required: true, message: '' }] }]" />
			</a-form-item>
			
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick";
import Editor from "../../../../components/Editor.vue"

// 表单字段
const fields = ["title", "info"];

export default {
	components: {
		Editor
	},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    return {
      form: this.$form.createForm(this)
    };
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v));
  },
  methods: {
    init(e) {
			let self = this
      this.form.setFieldsValue(pick(e, fields));
      this.visible = true
			this.$nextTick(()=>{
				self.$refs.editor.init(e.info)
			})
    },
    cancel() {
			this.form.resetFields()
      this.visible = false
    },
    ok() {
      this.form.validateFields((errors, values) => {
				this.form.resetFields();
        if (!errors) this.$emit("ok", values);
      });
    },
		update(e){
			this.form.setFieldsValue({info:e})
		}
  },
}
</script>
